<template >
  <b-container fluid class="container" style=" " >
      <loading :active.sync="isWorking" :is-full-page="true"></loading>
    <b-row align-h="center" align="center" class="title-logup">
        <b-col><h4 style="color:white;"><b-icon icon="person-circle"/> Registro nuevo usuario</h4></b-col>
        <b-col cols="12"><b-button pill size="sm" variant="warning" @click="goToCatalogo"><b-icon icon="arrow-left-circle"/> Catálogo Trámites</b-button><br> <br></b-col>
    </b-row>
    <b-card class="c-shadow-upuser">
        <b-row>
            <b-col md="4" cols="12">
                <b-form-group label="Nombre">
                    <b-form-input :state="!$v.dataUser.nombre.$invalid" style="text-transform: uppercase" type="text" v-model.trim="$v.dataUser.nombre.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.nombre.minLength">El Nombre es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.nombre.maxLength">El Nombre es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.nombre.required">Obligatorio</div>
                    <div class="error" v-if="!$v.dataUser.nombre.alpha">Solo ingresa caractéres de la A a la Z</div>
                </b-form-group>
            </b-col>
            <b-col md="4" cols="12">
                <b-form-group label="Apellido Paterno">
                    <b-form-input :state="!$v.dataUser.apellidoPat.$invalid" style="text-transform: uppercase" type="text" v-model.trim="$v.dataUser.apellidoPat.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.apellidoPat.minLength">El Nombre es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.apellidoPat.maxLength">El Nombre es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.apellidoPat.required">Obligatorio</div>
                    <div class="error" v-if="!$v.dataUser.apellidoPat.alpha">Solo ingresa caractéres de la A a la Z</div>
                </b-form-group>
            </b-col>
            <b-col md="4" cols="12">
                <b-form-group label="Apellido Materno">
                    <b-form-input :state="!$v.dataUser.apellidoMat.$invalid" style="text-transform: uppercase" type="text" v-model.trim="$v.dataUser.apellidoMat.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.apellidoMat.minLength">El Nombre es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.apellidoMat.maxLength">El Nombre es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.apellidoMat.required">Obligatorio</div>
                    <div class="error" v-if="!$v.dataUser.apellidoMat.alpha">Solo ingresa caractéres de la A a la Z</div>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col xl="4" md="4" cols="12">
                <b-form-group label="Curp">
                    <b-form-input :state="!$v.dataUser.curp.$invalid" style="text-transform: uppercase" type="text" v-model.trim="$v.dataUser.curp.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.curp.minLength">El Curp es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.curp.maxLength">El Curp es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.curp.required">Obligatorio</div>
                </b-form-group>
            </b-col>
            <b-col xl="3" md="4" cols="12">
                <b-button block variant="secondary" style="margin-top:30px;" href="https://www.gob.mx/curp/" target="_blank">Consulta tu CURP</b-button>
            </b-col>
        </b-row>
    </b-card>
    <b-card class="c-shadow-sm">
        <!--
        <b-row>
            <b-col md="4" cols="12">
                <b-form-group label="Calle">
                    <b-form-input :state="!$v.dataUser.calle.$invalid" style="text-transform: uppercase" type="text" v-model.trim="$v.dataUser.calle.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.calle.minLength">El nombre de calle es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.calle.maxLength">El nombre de calle es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.calle.required">Obligatorio</div>
                </b-form-group>
            </b-col>
            <b-col md="2" cols="12">
                <b-form-group label="No. Ext">
                    <b-form-input type="text" :state="!$v.dataUser.no.$invalid" style="text-transform: uppercase" v-model.trim="$v.dataUser.no.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.no.minLength">El número es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.no.maxLength">El número es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.no.required">Obligatorio</div>
                </b-form-group>
            </b-col>
            <b-col md="2" cols="12">
                <b-form-group label="No. Int">
                    <b-form-input :state="!$v.dataUser.noInt.$invalid" type="text" style="text-transform: uppercase" v-model.trim="$v.dataUser.noInt.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.noInt.minLength">El número es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.noInt.maxLength">El número es demasiado largo</div>
                </b-form-group>
            </b-col>
            <b-col md="4" cols="12">
                <b-form-group label="Código Postal">
                    <b-form-input :state="!$v.dataUser.cp.$invalid" type="number"  v-model.trim="$v.dataUser.cp.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.cp.between">El código postal es incorrecto</div>
                    <div class="error" v-if="!$v.dataUser.cp.minLength">El código postal es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.cp.maxLength">El código postal es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.cp.required">Obligatorio</div>
                    <div class="error" v-if="!$v.dataUser.cp.numeric">Ingresar un código postal válido</div>
                </b-form-group>
            </b-col>
        </b-row>
        -->
        <b-row>
            <b-col md="6" cols="12">
                <b-form-group label="Correo">
                    <b-form-input :state="!$v.dataUser.correo.$invalid"  type="email" v-model.trim="$v.dataUser.correo.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.correo.maxLength">El correo es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.correo.required">Obligatorio</div>
                    <div class="error" v-if="!$v.dataUser.correo.email">Debe ingresar un correo válido</div>
                </b-form-group>
            </b-col>
            <b-col md="6" cols="12">
                <b-form-group label="Teléfono">
                    <b-form-input :state="!$v.dataUser.telefono.$invalid" type="tel" v-model.trim="$v.dataUser.telefono.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.telefono.minLength">El teléfono es demasiado corto</div>
                    <div class="error" v-if="!$v.dataUser.telefono.maxLength">El teléfono es demasiado largo</div>
                    <div class="error" v-if="!$v.dataUser.telefono.required">Obligatorio</div>
                    <div class="error" v-if="!$v.dataUser.telefono.numeric">Debe ingresar un número válido</div>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
    <b-card class="c-shadow-sm">
        <b-row>
            <b-col md="4" cols="12">
                <b-form-group label="Contraseña">
                    <b-form-input :state="!$v.dataUser.pass.$invalid" type="password" v-model.trim="$v.dataUser.pass.$model"></b-form-input>
                    <div class="error" v-if="!$v.dataUser.pass.required">Obligatorio</div>
                    <div class="error" v-if="!$v.dataUser.pass.maxLength">La Contraseña es demasiado larga</div>
                </b-form-group>
            </b-col>
        </b-row>
    </b-card>
    <b-row style="margin-left:-13px;">
        <b-col cols="12">
            <vue-recaptcha ref="recaptchalogup" sitekey="6LdD09sUAAAAANCvTN2xMZqvfMOvzkdkOB1Zcpok" @verify="captchaLogupFilled" @expired="captchaLogupExpired" :loadRecaptchaScript="true" />
            <div class="error" v-if="msgErrorCaptcha!=''">{{msgErrorCaptcha}}</div><br>
        </b-col>
    </b-row>
    <b-alert :show="dismissCountDownErrorLogup" variant="danger" @dismiss-count-down="countDownChanged">{{msjErrorLogup}}</b-alert>
    <b-alert :show="msjErrorUser.time" dismissible variant="danger" @dismissed="msjErrorUser.time=0" @dismiss-count-down="countDownErrorUser">{{msjErrorUser.msj}}</b-alert>
    <b-row style="margin-left:-13px;">
        <b-col cols="12">
            <b-button size="lg" variant="success" @click="registrarse">Registrarse</b-button>
        </b-col>
    </b-row><br>

    <!--modals-->
    <b-modal title="Verificación de código" v-model="isShowModalVerificarCodigo" size="md" hide-footer>
        <div class="success">Hemos enviado un código de verificación a su correo electrónico.</div>
        <b-form-group label="Ingrese codigo de verificación:">
            <b-form-input :state="!$v.codigoVerificacion.$invalid" type="number" v-model.trim="codigoVerificacion"></b-form-input>
            <div class="error" v-if="!$v.codigoVerificacion.minLength">El código de verificación debe contener 6 números.</div>
            <div class="error" v-if="!$v.codigoVerificacion.maxLength">El código de verificación debe contener 6 números.</div>
            <div class="error" v-if="!$v.codigoVerificacion.numeric">Este campo solo admite números.</div>
            <div class="error" v-if="!$v.codigoVerificacion.required">Ingrese un un código de 6 digitos.</div>
        </b-form-group>
        <b-row>
            <b-col>
                <b-button block variant="success" @click="enviaPeticionVerificaCodigo">Verificar</b-button>
            </b-col>
            <b-col>
                <b-button block variant="danger" @click="reenviarCodigo">Reenviar Código</b-button>
            </b-col>
        </b-row>
    </b-modal>

    <b-modal v-model="isShowModalGoLogin" size="md" hide-footer @hidden="goToLogin()">
        <h4> Usuario Registrado Exitosamente, Por Favor Inicia Sesión.</h4>
        <b-button block variant="success" @click="goToLogin()">Iniciar Sesión</b-button>
    </b-modal>
  </b-container>
</template>

<script>
  import { minLength, maxLength, required, numeric, email, helpers, between } from 'vuelidate/lib/validators';
  import { logupUser, verificaCodigo } from '@/axios-tools';
  import VueRecaptcha from 'vue-recaptcha';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { mapState} from 'vuex';

  const alpha = helpers.regex('alpha', /^[a-zA-ZÁ-Úá-ú ]*$/)
  export default {
    computed: {
      ...mapState({
        token: state => state.user.token
      }),
      isShowModalGoLogin:{
        get: function() {return this.$store.state.user.isShowModalGoLogin;},
        set: function(newIsShowModalGoLogin) {this.$store.commit("updateIsShowModalGoLogin", newIsShowModalGoLogin);}
      },
      /*isShowNewUser:{
        get: function() {return this.$store.state.user.isShowNewUser;},
        set: function(isShowNewUser) {this.$store.commit("updateIsShowNewUser", isShowNewUser);}
      },*/
      dataUser:{
        get: function() {
            return this.formModelNewUser;
        },
        /*set: function(isShowNewUser) {this.$store.commit("updateIsShowNewUser", isShowNewUser);}*/
      },
      perfilUser: {
        get: function() {return this.$store.state.user.perfil;},
        set: function(newPerfil) {this.$store.commit("updatePerfil", newPerfil);}
      },
      isWorking: {
        get: function() {return this.$store.state.user.isWorking;},
        set: function(newIsWorking) {this.$store.commit("updateIsWorking", newIsWorking);}
      },
      msjErrorUser: {
        get: function() {return this.$store.state.user.msjErrorUser;},
        set: function(newMsjErrorUser) {this.$store.commit("updateMsjErrorUser", newMsjErrorUser);}
      },
      isShowModalVerificarCodigo: {
        get: function() {return this.$store.state.user.isShowModalVerificarCodigo;},
        set: function(isShowModalVerificarCodigo) {this.$store.commit("updateIsShowModalVerificarCodigo", isShowModalVerificarCodigo);}
      }
    },
    components: {
      Loading,
      VueRecaptcha
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        dismissCountDownErrorLogup:0,
        codigoVerificacion:"",
        captchaLogup: null,
        msgErrorCaptcha:'',
        msjErrorLogup:'',
        formModelNewUser:{
          nombre:"",
          apellidoPat:"",
          apellidoMat:"",
          curp:"",
          correo:"",
          telefono:"",
          calle:"",
          no:null,
          noInt:null,
          colonia:"",
          delMunicipio:"",
          estado:"",
          cp:"",
          pass:""
        }
      }
    },
    methods: {
        goToLogin(){
            this.$store.commit("updateIsShowModalGoLogin", false);
            this.$router.push('/' + this.cliente + '/loginuser');
        },
        countDownErrorUser(dismissCountDown){
            this.$store.commit("updateTimeMsjErrorUser", dismissCountDown);
        },
        reenviarCodigo(){
            this.$store.commit("updateIsShowModalVerificarCodigo",false);
        },
        enviaPeticionVerificaCodigo(){
            verificaCodigo(this.$store, this.$router, this.codigoVerificacion, this.perfilUser.idUser);
        },
        countDownChanged(dismissCountDownErrorLogup){
            this.dismissCountDownErrorLogup=dismissCountDownErrorLogup;
        },
        captchaLogupFilled(token){/* Método que es llamado cuando el captcha es resuelto correctamente */
            this.captchaLogup = token;
            this.msgErrorCaptcha='';
        },
        captchaLogupExpired(){/* Método que es llamado cuando el token(captcha) ha expirado */
            this.captchaLogup = null;
        },
        registrarse(){/* Método que envia la petición al servidor para registrar un usuario */
            if(!this.$v.dataUser.$invalid){
                if(this.captchaLogup!=null){
                    logupUser(this.$store, this.cliente, this.dataUser, this.captchaLogup);
                    this.msjErrorLogup='';
                    this.formModelNewUser={
                        nombre:"",apellidoPat:"",apellidoMat:"",curp:"",correo:"",telefono:"",calle:"",no:null,noInt:null,colonia:"",delMunicipio:"",estado:"",cp:"",pass:""}
                    this.$refs.recaptchalogup.reset();
                    this.captchaLogup = null;
                }else{
                    this.msgErrorCaptcha="Por favor complete el captcha";
                }
            }else{
                this.dismissCountDownErrorLogup=3;
                this.msjErrorLogup="Por favor completa todos los campos correctamente";
            }
        },
        goToCatalogo(){
            this.$router.push('/'+this.$route.params.municipio+'/catalogotramites')
        }
    },
    validations: {
        codigoVerificacion:{
            required,
            numeric,
            minLength: minLength(6),
            maxLength: maxLength(6)
        },
        dataUser:{
            pass:{
                required,
                maxLength: maxLength(10)
            },
            nombre:{
                alpha,
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            apellidoPat:{
                alpha,
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            apellidoMat:{
                alpha,
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            curp:{
                required,
                minLength: minLength(18),
                maxLength: maxLength(18)
            },
            correo:{
                email,
                required,
                maxLength: maxLength(150)
            },
            telefono:{
                numeric,
                required,
                minLength: minLength(10),
                maxLength: maxLength(10)
            },
            calle:{
                minLength: minLength(1),
                maxLength: maxLength(60)
            },
            no:{
                minLength: minLength(1),
                maxLength: maxLength(6)
            },
            noInt:{
                minLength: minLength(1),
                maxLength: maxLength(6)
            },
            cp:{
                numeric,
                between: between(1000, 99998),
                minLength: minLength(4),
                maxLength: maxLength(5)
            },
        }
    }
  }
</script>
<style>
.c-shadow-upuser{
  background-color:#FFFFFF;
  box-shadow: 5px 5px 20px -2px #5f5f5f;
  margin-bottom:25px;
  border-radius: 20px;
}
div.success{
  color: green;
  margin-bottom: 10px;
}
.title-logup{
    margin-bottom:20px;
}
div.error    {color: red;}
</style>